import '../Home.css';
import React from 'react';

import {CssBaseline, ThemeProvider, Box,Stack,Grid} from '@mui/material';

import { Components, Themes } from "@umany-global/design-system-react";

const {H6,Caption,Body2} = Components
const {light} = Themes

function Footer({isMobile}) {
  return (
    <ThemeProvider theme={light}>
        <CssBaseline />
        <Stack bgcolor={'#6c58a0'} sx={{
            backgroundImage:'url(../../assets/imgs/footer-bg.svg?)',
            backgroundPosition:'bottom center',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat'
        }} minHeight={'250px'} alignItems={'center'} justifyContent={'center'}>
            <Grid container justifyContent={'center'} maxWidth={'1000px'}>
            <Grid item md={4}>
                <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'} paddingRight={2} marginBottom={2}>
                <img alt="" src='../../assets/imgs/logo.svg' width={40}></img>
                <Stack>
                    <H6 color={'background.default'} >umany</H6>
                    <Caption color={'background.default'} fontWeight={'normal'}>El poder de tu huella</Caption>
                </Stack>
                </Stack>
            </Grid>
            <Grid item md={8}>
            <Stack spacing={1} paddingLeft={isMobile?0:4}>
                <Box width={'100%'} maxWidth={'270px'}>
                    <Body2 fontWeight={'bold'} color={'#FBAB00'}>Podemos lograr grandes cosas.</Body2>
                    <Body2 color={'background.default'} >¡Sumate a la #ComunidadUmany!</Body2>
                </Box>
                <Stack direction="row" spacing={1} alignItems={'center'}>
                    <Box width={40} height={40} borderRadius={'50%'} overflow={'hidden'}>
                    <a href="https://www.facebook.com/somosumany" target='blank'>
                        <img alt="" src="../../assets/imgs/fb-icon.svg" width="100%"></img>
                    </a>
                    </Box>
                    <Box width={40} height={40} borderRadius={'50%'} overflow={'hidden'}>
                    <a href="https://www.instagram.com/somosumany" target='blank'>
                        <img alt="" src="../../assets/imgs/ig-icon.svg" width="100%"></img>
                    </a>
                    </Box>
                    <Box >
                    <H6 color={'background.default'}>@somosumany</H6>
                    </Box>
                </Stack>
                </Stack>
            </Grid>
            </Grid>
        </Stack>
    </ThemeProvider>
  );
}

export default Footer;

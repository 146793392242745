import React, { useEffect } from 'react';
import Home from './Home';

import TiendanubeSettings from './tiendanube/Settings';
import TiendanubeSuccess from './tiendanube/Success';
import TiendanubeError from './tiendanube/Error';

import Terms from './Terms';
import Policy from './Policy';

import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
  
  const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/apps/tiendanube/welcome",
    element: <TiendanubeSuccess />,
  },
  {
    path: "/apps/tiendanube/integrations/errors",
    element: <TiendanubeError />,
  },
  {
    path: "/apps/tiendanube",
    element: <TiendanubeSettings />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/policy",
    element: <Policy />,
  },
  {
    path: "*",
    element: <Home />,
  },
  ]);
  

function App() {
  useEffect(() => {
    if(!document.getElementById('gtag')){
      // Añadir el script de Google Tag Manager de manera programática
      const gtag_script = document.createElement('script');
      gtag_script.id = "gtag"
      gtag_script.async = true;
      gtag_script.src = 'https://www.googletagmanager.com/gtag/js';
      document.head.appendChild(gtag_script);

      const gtag_js = document.createElement('script');
      gtag_js.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '`+process.env.REACT_APP_GTAG_ANALYTICS+`');  // Google Analytics
      `;

      process.env.REACT_APP_NODE_ENV==="prod"&&(gtag_js.innerHTML+=`gtag('config', '`+process.env.REACT_APP_GTAG_ADS+`');    // Google Ads`)

      document.head.appendChild(gtag_js);

      if(process.env.REACT_APP_NODE_ENV==="prod"){
        const fb_meta_el = document.createElement('meta');
        fb_meta_el.name="facebook-domain-verification"
        fb_meta_el.content=process.env.REACT_APP_FACEBOOK_DOMAIN_VERIFICATION
        document.head.appendChild(fb_meta_el);
      }
    }
  }, []);
  return (
    <RouterProvider router={router} />
  );
}

export default App;
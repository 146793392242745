// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  font-family: 'IsidoraSans';
  font-weight: 400;
}

body {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX","sourcesContent":["*{\r\n  font-family: 'IsidoraSans';\r\n  font-weight: 400;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import '../Home.css';
import React, { useEffect,useState } from 'react';

import {CssBaseline, ThemeProvider, Box,Stack} from '@mui/material';

import { Components, Themes } from "@umany-global/design-system-react";
import Footer from '../components/Footer';

const {H4,H6} = Components
const {light} = Themes

function Settings() {
  const [isMobile, setIsMobile] = useState(false);

  const handleWindowSizeChange = () => {
    if(window.innerWidth<500){
      setIsMobile(true)
    }else{
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(window.innerWidth<500){
      setIsMobile(true)
    }else{
      setIsMobile(false)
    }
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [isMobile]);

  return (
    <ThemeProvider theme={light}>
      <CssBaseline />
      <div className="App">
        

        {/*section*/}
        <Box bgcolor={'#F9F9F9'} position={'relative'} paddingBottom={isMobile?'80px':'120px'}>
          <Stack paddingTop={3} spacing={2} alignItems={'center'}>
            <Box paddingX={2}>

              <Box textAlign={"center"}>
                  <img alt="" src='../../assets/imgs/logo.svg' width={45}></img>
                  <H6 fontWeight={'bold'} >umany</H6>
                  
                  <H4 textAlign={'center'} fontWeight={'bold'} color={'primary.main'} mt={5}>Acá vas podés configurar tu app</H4>
                  <H6 textAlign={'center'} fontWeight={'bold'} color={'primary.main'}>Mas adelante vas a poder personalizar tu experiencia en umany.</H6>
                  
                  <Box width={'100%'} maxWidth={'500px'} mt={2} pl={5}>
                    <img src="../../assets/imgs/Switch container.svg" alt="" width="100%"></img>
                  </Box>
                  
                  <H6 textAlign={'center'} fontWeight={'bold'} color={'black'} mt={2}>Si tenés dudas o consultas</H6>
                  <a href="https://web.whatsapp.com/send?phone=+5491167613443&text=Hola%2C%20gracias%20por%20comunicarte%20con%20umany.%20Contanos%20en%20qu%C3%A9%20podemos%20ayudarte." rel="noreferrer" target='_blank'><H6 textAlign={'center'} fontWeight={'bold'} color={'primary.main'}>Comunicate con umany</H6></a>
              </Box>
              
            </Box>
          </Stack>
          <Box position={'absolute'} left={0} right={0} bottom={0} zIndex={2} overflow={'hidden'} display={'flex'}>
            <img alt="" width={'100%'} style={{
              backgroundColor:"#6c58a0"
            }} src='../../assets/imgs/sec-div-3-2.svg'></img>
          </Box>
        </Box>

        <Footer isMobile={isMobile} />
      
      </div>
    </ThemeProvider>
  );
}

export default Settings;

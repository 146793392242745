import './Home.css';
import React, { useEffect,useState } from 'react';

import {CssBaseline, ThemeProvider, Box,Stack,Grid,Button} from '@mui/material';

import { Components, Themes } from "@umany-global/design-system-react";

import { motion} from "framer-motion"
import Footer from './components/Footer';

const { H4,H3,H5,H6,ButtonPrimary,Caption} = Components
const {light} = Themes

function Home() {
  const [scrollY, setScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [btnNextOver, setBtnNextOver] = useState(false);
  const [section,setSection] = useState(0);
  const [isScrolling,setIsScrolling] = useState(false);  

  const handleWindowSizeChange = () => {
    if(window.innerWidth<500){
      setIsMobile(true)
    }else{
      setIsMobile(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    if(window.innerWidth<500){
      setIsMobile(true)
    }else{
      setIsMobile(false)
    }
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [isMobile]);

  const scrollToSmoothly = (pos, time) => {
    const start = window.scrollY || window.pageYOffset;
    const change = pos - start;
    let currentTime = 0;
    // Choose an increment that evenly divides the time
    const increment = time / Math.round(time / 16.67);

    function animateScroll() {
      currentTime += increment;
      const val = Math.easeInOut(currentTime, start, change, time);
      window.scrollTo(0, val);
      if (currentTime < time) {
        requestAnimationFrame(animateScroll);
      } else {
        setIsScrolling(false);
      }
    }

    animateScroll();
  };

  // Example of easing function for completeness
  Math.easeInOut = function (t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
  };

  function moveTo(to) {
    scrollToSmoothly(to, 1500);
  }

  const handleBtnNextOver = (e) => {
    setBtnNextOver(true)
  }
  const handleBtnNextOut = (e) => {
    setBtnNextOver(false)
  }
  const handleBtnNextClick = (e) => {
    if(section<4){
      //console.log('click')
      //console.log('next'+(section+1))
      const boxElement = document.getElementById('section1-text'+(section+1));
      moveTo( boxElement.offsetTop - ((window.innerHeight - boxElement.offsetHeight)/2))
      setIsScrolling(true)
      setSection((s)=>s+1)
    }    
  }

  

  useEffect(() => {
    //console.log('useEffect')
    const elements = document.querySelectorAll('.parallax-element');
    //const sec1_txt0 = document.getElementById('section1-text0')
    const sec1_txt1 = document.getElementById('section1-text1')
    const sec1_txt2 = document.getElementById('section1-text2')
    const sec1_txt3 = document.getElementById('section1-text3')
    const sec1_txt4 = document.getElementById('section1-text4')

    let invert = -1

    const handleMouseMove = (e) => {
      //if(!isScrolling){
      if(!isMobile){

        elements.forEach((element) => {
          const elementoRect = element.getBoundingClientRect();
          const mouseX = e.clientX;
          const mouseY = e.clientY;
  
          const distanciaX = mouseX - elementoRect.left - elementoRect.width / 2;
          const distanciaY = mouseY - elementoRect.top - elementoRect.height / 2;
          element.style.transform = `translateX(${invert*distanciaX / 100}px) translateY(${invert*distanciaY / 100}px)`;
          invert*=-1
        });
      }
      //}      
    };

    const handleScroll = (e) => {
      setScrollY(window.scrollY);      
  
      if(window.scrollY<=sec1_txt1.offsetTop-window.innerHeight){
        if (section !== 0) {
          //console.log('sec0')
          setSection(0)
        }      
      }else if(window.scrollY>=(sec1_txt1.offsetTop-window.innerHeight) && window.scrollY<=sec1_txt2.offsetTop-window.innerHeight){
        if (section !== 1) {
          //console.log('sec1')
          setSection(1)
        } 
      }else if(window.scrollY>=(sec1_txt2.offsetTop-window.innerHeight) && window.scrollY<=sec1_txt3.offsetTop-window.innerHeight){
        if (section !== 2) {
          //console.log('sec2',section)
          setSection(2)
        } 
      }else if(window.scrollY>=(sec1_txt3.offsetTop-window.innerHeight) && window.scrollY<=sec1_txt4.offsetTop-window.innerHeight){
        if (section !== 3) {
          //console.log('sec3')
          setSection(3)
        }
      }else if(window.scrollY>=(sec1_txt4.offsetTop-window.innerHeight)){
        if (section !== 4) {
          //console.log('sec4')
          setSection(4)
        }
      }
    }

    const handleWheel = (e) => {
      if (isScrolling) {
        e.preventDefault();
        e.stopPropagation();
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });
    window.addEventListener('scroll', handleScroll, { passive: false });
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.addEventListener('scroll', handleScroll);
      document.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('wheel', handleWheel);
    };
  }, [section,isScrolling,isMobile]);

  return (
    <ThemeProvider theme={light}>
      <CssBaseline />
      <div className="App">
        
        {/*

        <AppBar position="fixed" sx={{ backgroundColor: scrollPosition<100?'rgba(255,255,255,0.1)':'rgba(255,255,255,0.8)' }} id="back-to-top-anchor" >
        <Toolbar variant="dense" sx={{justifyContent:'center'}}>
          <Stack direction="row" spacing={2} alignItems={'center'} paddingY={1}>
            <img alt="" src='assets/imgs/logo.svg' width={45}></img>
            <H4 color={scrollPosition<100?'#fff':'#000'} ml={2} mt={1}>umany</H4>
          </Stack>
        </Toolbar>
      </AppBar>
        <Box bgcolor={'#eee'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} position={'relative'}>
          <Box zIndex={2} display={'flex'} paddingY={3} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} minHeight={700}>
            <H1 color={'background.default'} fontWeight={'bold'}>Ser umano</H1>
            <H4 mt={3} mb={4} color={'background.default'} fontWeight={'bold'} textAlign={'center'}>Explicar que es umany y porque te querrias sumar</H4>
            <ButtonPrimary color={'background'} sx={{backgroundColor:'#ffffff!important'}}>Sumate a nuestra comunidad</ButtonPrimary>
          </Box>
          <Box position={'absolute'} left={0} right={0} top={0} bottom={0} zIndex={1} overflow={'hidden'}>
            <Box sx={{
              backgroundImage:'url(assets/imgs/headerBg.png)',
              backgroundPosition:'center',
              backgroundSize:'cover',
              width:'100%',
              height:'100%'
            }}></Box>
          </Box>
          <Box position={'absolute'} left={0} right={0} bottom={0} zIndex={2} overflow={'hidden'} display={'flex'}>
            <img alt="" width={'100%'} src='assets/imgs/sec-div-1.svg'></img>
          </Box>
          
        </Box>
        */}

        {/*section*/}
        <Box bgcolor={'#fff'} sx={{
          background:'linear-gradient('+light.palette.grey["50"]+', '+light.palette.grey["300"]+')',
          backgroundImage:'url(assets/imgs/sky_1.svg)',
          backgroundPosition:'center',
          backgroundScale:'contain'
          }} position={'relative'} overflow={'hidden'} maxWidth={'100vw'}>
          <Stack alignItems={'center'} zIndex={1}>
            <Stack minHeight={'100vh'} zIndex={1} spacing={2} alignItems={'center'} justifyContent={'center'} paddingY={1}>
              <Box alignItems={'center'} textAlign={'center'}>

                <motion.div
                  initial={{ transform: `translateY(0px)` }}
                  whileInView={{ transform: `translateY(${scrollY*0.05}px)` }}
                >
                  <img alt="" src='assets/imgs/logo.svg' width={45}></img>
                  <H3 mt={1}>umany</H3>

                  <Box width={'100%'} maxWidth={'500px'} marginTop={-12}>
                    <img src="assets/imgs/svg_final/sec1.svg" alt="" width="100%"></img>
                  </Box>
                </motion.div>
              </Box>

              <Stack id={'section1-text0'} className='parallax-element' alignItems={'center'}  >
              {isMobile?
                <Box width={'100%'} maxWidth={'800px'} textAlign={'center'} marginTop={-10} paddingX={3}>
                  <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>Todo</H4>
                  <H4 display={'inline'} color={'primary'} > lo que hacemos, es para que las personas puedan </H4>
                  <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>dejar su huella</H4>
                  <H4 display={'inline'} color={'primary'} > en el mundo.</H4>
                </Box>
                :
                <Box width={'100%'} maxWidth={'800px'} textAlign={'center'} marginTop={-10} paddingX={3}>
                  <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>Todo</H3>
                  <H3 display={'inline'} color={'primary'} > lo que hacemos, es para que las personas puedan </H3>
                  <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>dejar su huella</H3>
                  <H3 display={'inline'} color={'primary'} > en el mundo.</H3>
                </Box>
                }
              </Stack>              
            </Stack>

            <Stack minHeight={'1000px'} justifyContent={'center'} alignContent={'center'} alignItems={"center"}>
              <Box position={"absolute"} width={'100%'} alignSelf={"center"} maxWidth={"700px"} marginTop={isMobile?"-300px":"-320px"}>
                <motion.div
                  initial={{ transform: `translateY(0px) translateX(0px)` }}
                  whileInView={{ transform: `translateY(${scrollY*0.05}px)` }}
                >
                  <img src="assets/imgs/svg_final/sec2.svg" alt="" width="100%"></img>
                </motion.div>
              </Box>
              {isMobile?
              <Box id={'section1-text1'} className='parallax-element' paddingX={3} width={'100%'} maxWidth={'800px'} textAlign={'center'} paddingTop={isMobile?"200px":"220px"}>
                <H4 display={'inline'} color={'primary'} >Imaginate que </H4>
                <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>después</H4>
                <H4 display={'inline'} color={'primary'} > de cada compra que hacés, </H4>
                <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>la marca destina un porcentaje</H4>
                <H4 display={'inline'} color={'primary'} > a una </H4>
                <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>causa</H4>
                <H4 display={'inline'} color={'primary'} > social, ambiental o de protección animal</H4>
              </Box>
              :
              <Box id={'section1-text1'} className='parallax-element' paddingX={3} width={'100%'} maxWidth={'800px'} textAlign={'center'} paddingTop={"200px"}>
                <H3 display={'inline'} color={'primary'} >Imaginate que </H3>
                <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>después</H3>
                <H3 display={'inline'} color={'primary'} > de cada compra que hacés, </H3>
                <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>la marca destina un porcentaje</H3>
                <H3 display={'inline'} color={'primary'} > a una </H3>
                <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>causa</H3>
                <H3 display={'inline'} color={'primary'} > social, ambiental o de protección animal</H3>
              </Box>
              
              }
            </Stack>

            <Stack minHeight={'800px'} justifyContent={'center'} paddingX={3} alignContent={'center'}>
              <Box position={"absolute"} width={'100%'} alignSelf={"center"} maxWidth={"700px"} marginTop={isMobile?"-500px":"-520px"}>
                <motion.div
                  initial={{ transform: `translateY(0px) translateX(0px)` }}
                  whileInView={{ transform: `translateY(${scrollY*0.05}px)` }}
                >
                  <img src="assets/imgs/svg_final/sec3.svg" alt="" width="100%"></img>
                </motion.div>
              </Box>
              {isMobile?
              <Box id={'section1-text2'} className='parallax-element' paddingTop={"200px"}>
                
                <Box width={'100%'} maxWidth={'760px'} textAlign={'center'}>
                  <H4 display={'inline'} color={'primary'} >¿Cómo sería el </H4>
                  <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>mundo</H4>
                  <H4 display={'inline'} color={'primary'} > si las marcas, ONGs y los consumidores tuviéramos </H4>
                  <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>objetivos en común</H4>
                  <H4 display={'inline'} color={'primary'} >?</H4>
                </Box>         

                <Box width={'100%'} maxWidth={'740px'} paddingX={3} textAlign={'center'} marginTop={5}>
                  <H4 display={'inline'} color={'primary'} >¿Y si, en </H4>
                  <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>cada compra</H4>
                  <H4 display={'inline'} color={'primary'} > que hacemos, pudiéramos </H4>
                  <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>dejar una huella</H4>
                  <H4 display={'inline'} color={'primary'} >?</H4>
                </Box>     
              </Box> 
              :
              <Box id={'section1-text2'} className='parallax-element' paddingTop={"250px"}>
                
                <Box width={'100%'} maxWidth={'760px'} textAlign={'center'}>
                  <H3 display={'inline'} color={'primary'} >¿Cómo sería el </H3>
                  <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>mundo</H3>
                  <H3 display={'inline'} color={'primary'} > si las marcas, ONGs y los consumidores tuviéramos </H3>
                  <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>objetivos en común</H3>
                  <H3 display={'inline'} color={'primary'} >?</H3>
                </Box>         

                <Box width={'100%'} maxWidth={'740px'} paddingX={3} textAlign={'center'} marginTop={5}>
                  <H3 display={'inline'} color={'primary'} >¿Y si, en </H3>
                  <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>cada compra</H3>
                  <H3 display={'inline'} color={'primary'} > que hacemos, pudiéramos </H3>
                  <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>dejar una huella</H3>
                  <H3 display={'inline'} color={'primary'} >?</H3>
                </Box>     
              </Box> 
              }
              
            </Stack>

            <Stack  minHeight={'700px'} justifyContent={'center'} alignContent={'center'}>
              <Box position={"absolute"} width={'100%'} alignSelf={"center"} maxWidth={"800px"} marginTop={isMobile?"-500px":"-520px"}>
                <motion.div
                  initial={{ transform: `translateY(0px) translateX(0px)` }}
                  whileInView={{ transform: `translateY(${scrollY*0.05}px)` }}
                >
                  <img src="assets/imgs/svg_final/sec4.svg" alt="" width="100%"></img>
                </motion.div>
              </Box>
              {isMobile?

              <Box className='parallax-element' id={'section1-text3'} paddingX={3} width={'100%'} maxWidth={'720px'} textAlign={'center'} >
                <H4 display={'inline'} color={'primary'} >Por eso </H4>
                <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>transformamos</H4>
                <H4 display={'inline'} color={'primary'} > el consumo tradicional en un </H4>
                <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>Consumo que suma</H4>
              </Box>
              :
              <Box className='parallax-element' id={'section1-text3'} paddingX={3} width={'100%'} maxWidth={'720px'} textAlign={'center'} paddingTop={"150px"}>
                <H3 display={'inline'} color={'primary'} >Por eso </H3>
                <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>transformamos</H3>
                <H3 display={'inline'} color={'primary'} > el consumo tradicional en un </H3>
                <H3 display={'inline'} color={'primary'} fontWeight={'bold'}>Consumo que suma</H3>
              </Box>
              }
            </Stack>


            <Stack minHeight={'800px'} justifyContent={'center'} alignContent={'center'}>
              <Box id={'section1-text4'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <Box width={'100%'} maxWidth={'500px'}>
                  <img src="assets/imgs/section1-text3-img.svg" alt="" width="100%"></img>
                </Box>

                <Box marginTop={10} alignSelf={'center'} width={'100%'} maxWidth={'200px'}>
                  <ButtonPrimary className={"btn-fix"} color={'primary'} onClick={()=>{
                    window.location = "https://www.instagram.com/somosumany/";
                  }}  >Sumá tu huella</ButtonPrimary>
                </Box>  

                <Box marginTop={2} width={'100%'} maxWidth={'720px'} textAlign={'center'} paddingX={2}>
                  <H4 display={'inline'} color={'primary'} >Está por llegar. </H4>
                  <H4 display={'inline'} color={'primary'} >Seguinos y </H4>
                  <H4 display={'inline'} color={'primary'} fontWeight={'bold'}>enterate cuando.</H4>
                </Box>
                
              </Box>

              
            </Stack>

          
            <Box position={'absolute'} left={50} top={'750px'} zIndex={2} display={'flex'} >
              <motion.div
              initial={{ transform: `translateY(0px) translateX(0px)` }}
              whileInView={{ transform: `translateX(${scrollY*0.1}px)` }}
              >
                <img alt="" width={'150px'} src='assets/imgs/cloud.svg'></img>
              </motion.div>
            </Box>

            <Box position={'absolute'} left={200} top={'150px'} zIndex={2} display={'flex'}>
              <motion.div
                initial={{ transform: `translateY(0px) translateX(0px)` }}
                whileInView={{ transform: `translateX(${scrollY*0.1}px)` }}
              >
                <img alt="" width={'50px'} src='assets/imgs/cloud.svg'></img>
              </motion.div>
            </Box>

            <Box position={'absolute'} right={200} top={'1200px'} zIndex={2} display={'flex'}>
              <motion.div
                initial={{ transform: `translateY(0px) translateX(0px)` }}
                whileInView={{ transform: `translateX(${scrollY*0.05}px)` }}
              >
                <img alt="" width={'250px'} src='assets/imgs/cloud.svg'></img>
              </motion.div>
            </Box>

            <Box position={'absolute'} left={-150} top={'1800px'} zIndex={2} display={'flex'}>
              <motion.div
                initial={{ transform: `translateY(0px) translateX(0px)` }}
                whileInView={{ transform: `translateX(${scrollY*0.04}px)` }}
              >
                <img alt="" width={'250px'} src='assets/imgs/cloud.svg'></img>
              </motion.div>
            </Box>

            <Box position={'absolute'} right={-150} top={'2400px'} zIndex={2} display={'flex'}>
              <motion.div
                initial={{ transform: `translateY(0px) translateX(0px)` }}
                whileInView={{ transform: `translateX(-${scrollY*0.03}px)` }}
              >
                <img alt="" width={'250px'} src='assets/imgs/cloud.svg'></img>
              </motion.div>
            </Box>

            <Box position={'absolute'} left={150} top={'3000px'} zIndex={2} display={'flex'}>
              <motion.div
                initial={{ transform: `translateY(0px) translateX(0px)` }}
                whileInView={{ transform: `translateX(${scrollY*0.02}px)` }}
              >
                <img alt="" width={'250px'} src='assets/imgs/cloud.svg'></img>
              </motion.div>
            </Box>

            <Box position={'absolute'} right={-150} top={'3800px'} zIndex={2} display={'flex'}>
              <motion.div
                initial={{ transform: `translateY(0px) translateX(0px)` }}
                whileInView={{ transform: `translateX(${scrollY*0.01}px)` }}
              >
                <img alt="" width={'250px'} src='assets/imgs/cloud.svg'></img>
              </motion.div>
            </Box>


          </Stack>

          <Box id={'btn-next'} position={'fixed'} left={0} right={0} bottom={30} zIndex={3} display={section>=4?'none':'flex'} justifyContent={'center'}>
            <Button width={50} height={50}
            onMouseEnter={handleBtnNextOver}
            onMouseLeave={handleBtnNextOut}
            onClick={handleBtnNextClick}
            style={{
              transition: 'transform 0.3s ease-in-out',
              ...(btnNextOver && { transform: `translateY(10px)` }), // Ajusta el desplazamiento según tus preferencias
            }}
            >
              <img alt="" width={30} height={50} src='assets/imgs/expand_more.svg'></img>
            </Button>
          </Box>
          
        </Box>

        {/*<Stack alignItems={"center"} paddingBottom={10} paddingTop={10} bgcolor={"#F9F9F9"}>
          <Grid container maxWidth={'900px'}>
              <Stack direction="column">
                <Box paddingX={2} marginBottom={5} textAlign={"center"}>
                  <H3 display={'inline'} color={'#371E80'} textAlign={'center'}>Conocé </H3>
                  <H3 display={'inline'} color={'#371E80'} fontWeight={'bold'} textAlign={'center'}>la causa </H3>
                  <H3 display={'inline'} color={'#371E80'} textAlign={'center'}>que podés impulsar</H3>
                </Box>

                <Stack direction={isMobile?"column":"row"} paddingX={3}>
                  <Box width={isMobile?"100%":'45%'}>
                    <H4 color={'#371E80'} fontWeight={'bold'}>Umanizando tu compra</H4>
                    <H4 color={'#371E80'} fontWeight={'bold'}>ayudás a Baristas con Alma</H4>
                    <Box marginTop={3}>
                      <H4 display={'inline'}>Podés destinar un porcentaje de tu compra en Casa humana Café y ayudar a que más personas con discapacidad de la </H4>
                      <H4 display={'inline'}><a href="https://www.instagram.com/fundacion.almahumana/" target='_blank' rel="noopener noreferrer">fundación Alma Humana </a></H4>
                      <H4 display={'inline'}>puedan estudiar y formarse como Baristas.</H4>
                    </Box>

                    <Box marginTop={6}>
                      <H6 fontWeight={'bold'}>Objetivo: </H6>
                      <H6 >Comprar 10 bolsas de cafe para los talleres. </H6>
                    </Box>

                    <Box marginTop={2} position={"relative"} >
                      <Box display={"flex"} height={"10px"} alignItems={"flex-start"} borderRadius={"50px"} backgroundColor={"#eee"}>
                        <Box borderRadius={"50px"} width={"0%"} height={"100%"} sx={{background:"linear-gradient(90deg, rgba(50,21,138,1) 0%, rgba(137,6,169,1) 17%, rgba(174,24,142,1) 63%, rgba(251,116,21,1) 92%, rgba(252,171,1,1) 100%)"}}></Box>
                      </Box>
                      <Box position={"absolute"} top={"-5px"} left={0} right={0}>
                        <img alt="" width="15px" style={{marginLeft:"calc(0% - 0px)"}} src={'assets/imgs/logo.svg'} />
                      </Box>
                    </Box>

                    <Box display={"flex"}>
                      <Box flex={1} alignSelf={"flex-start"}>
                        <Caption >$0</Caption>
                      </Box>
                      <Box alignSelf={"flex-end"}>
                        <Caption >$400.000</Caption>
                      </Box>
                    </Box>

                    <a href="https://www.instagram.com/" style={{textDecoration:"none"}} target='blank' rel="noopener noreferrer">
                      <Box display={"flex"} alignItems={"center"} marginY={4}>
                        <Box width={25} height={25} bgcolor={"#7164A1"} borderRadius={'50%'} overflow={'hidden'} >
                            <img alt="" src="assets/imgs/ig-icon.svg" width="45px" height="45px" style={{marginLeft:"-10px",marginTop:"-10px"}}></img>
                        </Box>
                        <H5 fontWeight={'bold'} style={{marginLeft:"10px",marginBottom:"3px"}}>Conocé más de esta historia.</H5>
                      </Box>
                    </a>
                  </Box>
                  <Box width={isMobile?"100%":'15%'}></Box>
                  <Box width={isMobile?"100%":'40%'}>
                    <img alt="" width="100%" src={'assets/imgs/causa.jpg'} />
                  </Box>
                </Stack>

                <Box paddingX={2} marginTop={6}>
                  <H5 color={'#371E80'} textAlign={'center'}>Ingresá a la tienda para realizar una compra y</H5>
                  <H5 color={'#371E80'} fontWeight={'bold'} textAlign={'center'}>empezá a ser parte del cambio</H5>
                </Box>

                <Box width={"100%"} alignItems={"center"} justifyContent={"center"} display={"flex"} marginTop={7}>
                  
                  <a style={{textDecoration:"none",paddingTop:"30px",paddingBottom:"30px",boxShadow:"0 4px 4px 0 rgba(0,0,0,0.15)",borderRadius:"24px",backgroundColor:"#fff",maxWidth:"192px",width:"100%",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column",marginRight:"15px",marginLeft:"15px"}} href="https://www.instagram.com/casaveganabsas/" target='_blank' rel="noopener noreferrer">
                    <Box width={"90px"} height={"90px"} bgcolor={"#7164A1"} borderRadius={'50%'} overflow={'hidden'} >
                        <img alt="" src="assets/imgs/logo-casavegana.png" width="90px" height="90px"></img>
                    </Box>
                    <H6 color={'#371E80'} fontWeight={'bold'} textAlign={'center'} marginTop={2}>CASA VEGANA</H6>
                    <H6 textAlign={'center'} >Gastronomía</H6>
                  </a>

                  <a style={{textDecoration:"none",paddingTop:"30px",paddingBottom:"30px",boxShadow:"0 4px 4px 0 rgba(0,0,0,0.15)",borderRadius:"24px",backgroundColor:"#fff",maxWidth:"192px",width:"100%",alignItems:"center",justifyContent:"center",display:"flex",flexDirection:"column",marginRight:"15px",marginLeft:"15px"}} href="https://www.instagram.com/casahumana.cafe/" target='_blank' rel="noopener noreferrer">
                    <Box width={"90px"} height={"90px"} bgcolor={"#7164A1"} borderRadius={'50%'} overflow={'hidden'} >
                        <img alt="" src="assets/imgs/logo-casahumana.png" width="90px" height="90px"></img>
                    </Box>
                    <H6 color={'#371E80'} fontWeight={'bold'} textAlign={'center'} marginTop={2}>CASA HUMANA CAFE</H6>
                    <H6 textAlign={'center'} >Gastronomía</H6>
                  </a>
                </Box>
                
              </Stack>
          </Grid>
        </Stack>*/}

        <Stack bgcolor={'#6c58a0'} sx={{
            backgroundImage:'url(assets/imgs/svg_final/sec5.svg)',
            backgroundPosition:'bottom center',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat'
          }} minHeight={'300px'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
          <Grid container justifyContent={'center'} maxWidth={'1000px'}>
              <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                <Stack>
                  <Box paddingX={2}>
                    <H3 color={'#FFFFFF'} fontWeight={'bold'} textAlign={'center'}>¿Querés sumar tu marca?</H3>
                  </Box>
                  <Box marginTop={2} alignSelf={'center'} width={'100%'} maxWidth={'230px'}>
                    <ButtonPrimary className={"btn-fix"} color={'primary'} onClick={()=>{
                window.location = "https://www.instagram.com/somosumany/";
              }}  >Sí, quiero sumarme</ButtonPrimary>
                  </Box>
                </Stack>
              </Stack>
          </Grid>
        </Stack>

        

        <Box bgcolor={'#fff'} position={'relative'} paddingBottom={'80px'}>
          <Stack paddingY={5} alignItems={'center'}>
            <Box paddingX={2} marginTop={1}>
              <H3 textAlign={'center'} fontWeight={'bold'} color={'primary.main'}>Manifiesto umany</H3>
            </Box>
            <Box paddingX={3} width={'100%'} maxWidth={'720px'} textAlign={'center'} marginTop={1}>
              <H5 display={'inline'} color={'primary'} >Somos humanos por naturaleza, umanos por elección</H5>
            </Box>

            <Box display={"flex"} flexDirection={"column"} width={"100%"} maxWidth={"700px"} marginTop={6} paddingX={isMobile?2:0}>

              <Box width={'100%'} maxWidth={'350px'} alignSelf={isMobile?"center":"flex-start"}>
                <Box display={"flex"} marginBottom={2}>
                  <Box marginRight={2}>
                    <img alt="" width="60px" src={'assets/imgs/Icon-1.svg'} />
                  </Box>
                  <Box>
                    <H5 fontWeight={'bold'} >Ser umano</H5>
                    <H5 >Reconocemos el impacto que tienen nuestras elecciones en otros seres y en el medio ambiente</H5>
                  </Box>
                </Box>
                <img alt="" width="100%" src={'assets/imgs/Line-1.svg'} />
              </Box>

              <Box width={'100%'} maxWidth={'350px'} alignSelf={isMobile?"center":"flex-end"}>
                <Box display={"flex"} marginBottom={2}>
                 <Box marginRight={2}>
                    <img alt="" width="60px" src={'assets/imgs/Icon-2.svg'} />
                  </Box>
                  <Box>
                    <H5 fontWeight={'bold'} >Cambiar el mundo</H5>
                    <H5 >Nuestro propósito es transformar nuestra  humanidad, respetando la diversidad</H5>
                  </Box>
                </Box>
                <img alt="" width="100%" src={'assets/imgs/Line-2.svg'} />
              </Box>

              <Box width={'100%'} maxWidth={'350px'} alignSelf={isMobile?"center":"flex-start"}>
                <Box display={"flex"} marginBottom={2}>
                  <Box marginRight={2}>
                    <img alt="" width="60px" src={'assets/imgs/Icon-3.svg'} />
                  </Box>
                  <Box>
                    <H5 fontWeight={'bold'} >Nuestra huella vale</H5>
                    <H5 >Buscamos sumar con acciones cotidianas. Cada elección individual nos inspira al cambio colectivo.</H5>
                  </Box>
                </Box>
                <img alt="" width="100%" src={'assets/imgs/Line-3.svg'} />
              </Box>

              <Box width={'100%'} maxWidth={'350px'} alignSelf={isMobile?"center":"flex-end"}>
                <Box display={"flex"} marginBottom={2}>
                  <Box marginRight={2}>
                    <img alt="" width="60px" src={'assets/imgs/Icon-4.svg'} />
                  </Box>
                  <Box>
                    <H5 fontWeight={'bold'} >Tachame la H</H5>
                    <H5 >Sabemos que juntos podemos ser más umanos y empáticos con nuestro planeta.</H5>
                  </Box>
                </Box>
              </Box>

            </Box>

          </Stack>
          <Box position={'absolute'} left={0} right={0} bottom={0} zIndex={2} overflow={'hidden'} display={'flex'}>
            <img alt="" width={'100%'} src='assets/imgs/sec-div-2.svg'></img>
          </Box>
        </Box>

        {/*section*/}
        <Box bgcolor={'#F9F9F9'} minHeight={800} position={'relative'} paddingBottom={'80px'}>
          <Stack paddingY={3} spacing={2} alignItems={'center'}>
            <Box paddingX={2}>
              <H3 textAlign={'center'} fontWeight={'bold'} color={'primary.main'}>umanos que impulsan umany</H3>
            </Box>

            <Grid container spacing={2} paddingY={1} justifyContent={'center'} maxWidth={'1000px'}>
              <Grid className='linkedin-item' item xs={12} md={3} sm={6} alignItems={'center'}>
                <a className='linkedin-card' href="https://www.linkedin.com/in/krazydanok/" target='blank'>
                  <Stack alignItems={'center'} spacing={2} padding={2} >
                    <Box className='linkedin-card-img' width={190} height={190} bgcolor={'grey.400'} borderRadius={'50%'} overflow={'hidden'}>
                      <img alt="" src="assets/imgs/dan.jpg" width="100%"></img>
                    </Box>
                    <Box display={'flex'}>
                      <img alt="" width={'20px'} style={{marginRight:'10px'}} src="assets/imgs/LinkedIn_icon.svg"></img>
                      <H5 textAlign={'center'} fontWeight={'bold'}>Dan</H5>
                    </Box>
                  </Stack>
                </a>
              </Grid>
              <Grid className='linkedin-item' item xs={12} md={3} sm={6}>
                <a className='linkedin-card' href="https://www.linkedin.com/in/tomaspiccinini/" target='blank'>
                  <Stack alignItems={'center'} spacing={2} padding={2} >
                    <Box className='linkedin-card-img' width={190} height={190} bgcolor={'grey.400'} borderRadius={'50%'} overflow={'hidden'}>
                      <img alt="" src="assets/imgs/tom.jpg" width="100%"></img>
                    </Box>
                    <Box display={'flex'}>
                      <img alt="" width={'20px'} style={{marginRight:'10px'}} src="assets/imgs/LinkedIn_icon.svg"></img>
                      <H5 textAlign={'center'} fontWeight={'bold'}>Tom</H5>
                    </Box>
                  </Stack>
                </a>
              </Grid>
              <Grid className='linkedin-item' item xs={12} md={3} sm={6}>
                <a className='linkedin-card' href="https://www.linkedin.com/in/brendaumeres/" target='blank'>
                  <Stack alignItems={'center'} spacing={2} padding={2} >
                    <Box className='linkedin-card-img' width={190} height={190} bgcolor={'grey.400'} borderRadius={'50%'} overflow={'hidden'}>
                      <img alt="" src="assets/imgs/bren.jpg" width="100%"></img>
                    </Box>
                    <Box display={'flex'}>
                      <img alt="" width={'20px'} style={{marginRight:'10px'}} src="assets/imgs/LinkedIn_icon.svg"></img>
                      <H5 textAlign={'center'} fontWeight={'bold'}>Bren</H5>
                    </Box>
                  </Stack>
                </a>
              </Grid>
              <Grid className='linkedin-item' item xs={12} md={3} sm={6}>
                <a className='linkedin-card' href="https://www.linkedin.com/in/pamelasalgado-comunicaci%C3%B3n-estrategia/" target='blank'>
                  <Stack alignItems={'center'} spacing={2} padding={2} >
                    <Box className='linkedin-card-img' width={190} height={190} bgcolor={'grey.400'} borderRadius={'50%'} overflow={'hidden'}>
                      <img alt="" src="assets/imgs/pam.jpg" width="100%"></img>
                    </Box>
                    <Box display={'flex'}>
                      <img alt="" width={'20px'} style={{marginRight:'10px'}} src="assets/imgs/LinkedIn_icon.svg"></img>
                      <H5 textAlign={'center'} fontWeight={'bold'}>Pam</H5>
                    </Box>
                  </Stack>
                </a>
              </Grid>
              <Grid className='linkedin-item' item xs={12} md={3} sm={6}>
                <a className='linkedin-card' href="https://www.linkedin.com/in/ingridsalgado/" target='blank'>
                  <Stack alignItems={'center'} spacing={2} padding={2} >
                    <Box className='linkedin-card-img' width={190} height={190} bgcolor={'grey.400'} borderRadius={'50%'} overflow={'hidden'}>
                      <img alt="" src="assets/imgs/in.png" width="100%"></img>
                    </Box>
                    <Box display={'flex'}>
                      <img alt="" width={'20px'} style={{marginRight:'10px'}} src="assets/imgs/LinkedIn_icon.svg"></img>
                      <H5 textAlign={'center'} fontWeight={'bold'}>Ingrid</H5>
                    </Box>
                  </Stack>
                </a>
              </Grid>
              <Grid className='linkedin-item' item xs={12} md={3} sm={6}>
                <a className='linkedin-card' href="https://www.linkedin.com/in/marialehernandezf/" target='blank'>
                  <Stack alignItems={'center'} spacing={2} padding={2} >
                    <Box className='linkedin-card-img' width={190} height={190} bgcolor={'grey.400'} borderRadius={'50%'} overflow={'hidden'}>
                      <img alt="" src="assets/imgs/mariale.jpg" width="100%"></img>
                    </Box>
                    <Box display={'flex'}>
                      <img alt="" width={'20px'} style={{marginRight:'10px'}} src="assets/imgs/LinkedIn_icon.svg"></img>
                      <H5 textAlign={'center'} fontWeight={'bold'}>Mari</H5>
                    </Box>
                  </Stack>
                </a>
              </Grid>
            </Grid>
          </Stack>
          <Box position={'absolute'} left={0} right={0} bottom={0} zIndex={2} overflow={'hidden'} display={'flex'}>
            <img alt="" width={'100%'} style={{
              backgroundColor:"#6c58a0"
            }} src='assets/imgs/sec-div-3-2.svg'></img>
          </Box>
        </Box>

        <Footer isMobile={isMobile} />
      
      </div>
    </ThemeProvider>
  );
}

export default Home;
